import { Card, Checkbox, Chip, Fade, FormControlLabel, IconButton, LinearProgress, makeStyles, Menu, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { ArrowBackIosSharp, ArrowForwardIosSharp, Close, CloseRounded, ImportExport } from '@material-ui/icons';
import clsx from 'clsx';
import { ClrdButton, FormTextField } from 'fitbud/components/form-fields';
import { CopyIcon } from 'fitbud/icons/copy2';
import { DeleteIcon } from 'fitbud/icons/delete';
import { PasteIcon } from 'fitbud/icons/paste2';
import { Draggable } from 'react-beautiful-dnd';
import { DurationDropdown } from '../users/subComponents';
import { durationInTypes, getAllIDsToFetch, getNavigateRange, MAX_WEEK_VIEW, processScheduleMeta, processMlScheduleMeta, generateMetaMap, getWeekTitle } from './helper';
import WorkoutCard from './scheduleWorkoutCard';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import ScheduleMlCard from './scheduleMlCard';
import KeyDown from "@material-ui/icons/KeyboardArrowDown";
import { bffGetCatalogByIDs } from 'fitbud/api';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import DetailEditIcon from "fitbud/icons/detailEdit";
import Dialog from 'fitbud/components/Dialog';
import { AddIcon } from 'fitbud/icons/addIconRounded';
import TagIcon from 'fitbud/icons/tag';

const styles = makeStyles((theme) => ({
  card: {
    height: ({ minified }) => minified ? '72px' : '80px',
    // width: ({ minified }) => minified ? '160px' : '172px',
    minWidth: ({ minified }) => minified ? '160px' : '172px',
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none'
  },
  overlayCard: {
    backgroundColor: "rgba(101, 118, 140, 0.9)",
    display: 'flex',
    height:'100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
  },
  overlayCardRoot:{
    height:'100%',
    width: '100%',
    position:'absolute',
    left: 0,
    zIndex: 2,
  },
  metaField:{
    minWidth: "230px",
    height:"40px",
  },
  chip: {
    borderRadius: 40,
    backgroundColor: '#F0F3F5',
    color: '#000',
    border: '1px solid transparent',
  },
  chipSmall:  {
    height: 22,
  },
  chipLarge: {
    height: 30,
  },
  selectedChip: {
    borderColor: '#317FF5',
    backgroundColor: 'unset',
    color: '#317FF5',
  }
}));

const autoRepeatError = 
  'The schedule must be in weeks, not days or months to use this feature';

export const HeaderActions = (props) => {
  const { localState, updateLocalState, selectedRange, totalWeeks, updateSelectedRange,
    setDirty, toggleReorderDialog, toggleScheduleImport, fetchedItems, updateFetchedItems, scheduleType, setItemsMeta,
    modifySchDuration=true,showImportBtn=false } = props;
  const { duration, durationType, weekday_align } = localState;
  
  const { cid } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const autoRepeatCheck = duration % 7 === 0 && weekday_align === 2;
  
  const handleChange = (e, params) => {
    setDirty(true);
    const { duration: newDuration } = params;
    const [start, end] = [...selectedRange];
    let newTotalWeeks = Math.ceil(newDuration / 7);
    if(newTotalWeeks <= start ){
      // Adjust week range too it
      if(newTotalWeeks <= MAX_WEEK_VIEW) updateSelectedRange([1, MAX_WEEK_VIEW]);
      else {
        let newStart = newTotalWeeks - (newTotalWeeks - MAX_WEEK_VIEW);
        if(newStart === MAX_WEEK_VIEW) newStart = (newTotalWeeks - MAX_WEEK_VIEW) <=0 ? newTotalWeeks : newTotalWeeks - MAX_WEEK_VIEW;
        updateSelectedRange([newStart, newTotalWeeks]);
      }
    } 
    if(end <= newTotalWeeks){
      if((start+MAX_WEEK_VIEW) >= newTotalWeeks) updateSelectedRange([start, newTotalWeeks]);
      else updateSelectedRange([start, (start - 1) + MAX_WEEK_VIEW]);
    }
    const itemsMeta = generateMetaMap({ ...localState, ...params }, scheduleType, fetchedItems);
    setItemsMeta(itemsMeta);
    updateLocalState(prev => ({ ...prev, ...params }));
  };

  const handleAutoRepeat = (event) => {
    const { checked } = event.target
    if(duration % 7 !== 0) {
      enqueueSnackbar(autoRepeatError, {variant: 'error'})
      return
    }
    setDirty(true);
    
    let autoRepeat = null;
    if(checked) autoRepeat = 2
    updateLocalState(prev => ({...prev, weekday_align: autoRepeat}))
  }

  const navigate = (direction) => {
    const newRange = getNavigateRange(selectedRange, totalWeeks, direction);
    let allIds = getAllIDsToFetch(localState, scheduleType, [...newRange]);
    allIds = allIds.filter(o => !fetchedItems[o]);
    if(allIds.length > 0){
      const _items = {};
      setLoading(true);
      return bffGetCatalogByIDs({cid, [scheduleType+'s']: [ ...allIds ]})
        .then(({ data: _resData = { } }) => {
          _.get(_resData, scheduleType+'s', []).forEach((itemData) => {
            _items[itemData._id] = { ...itemData };
        });
        updateFetchedItems(prev => ({ ...prev, ..._items }));
        updateSelectedRange([...newRange]);
      })
      .catch((err) => enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' }))
      .finally(() => setLoading(false));
    }
    setLoading(true);
    updateSelectedRange([...newRange]);
    setLoading(false);
  };

  return (
    <div className="fpx-20">
      <div className='d-flex w-100 justify-content-between bg-white py-10 px-20 mt-20 rounded border'>
        <div className='d-flex justify-content-between align-items-center ml-n10'>
          <div className='d-flex align-items-center fmr-30'>
            <IconButton disabled={isLoading || selectedRange[0] === 1} onClick={(e) => navigate('prev')}>
              <ArrowBackIosSharp classes={{ root: 'small' }} />
            </IconButton>
            <Typography className='font_16_500' >
              Week {' '}
              <span className='font_16_600'>
                {selectedRange[0]} {`- ${(selectedRange[1] >= totalWeeks) ? selectedRange[1] - (selectedRange[1] - totalWeeks) : selectedRange[1]}`}
              </span>
              {' '}  of {totalWeeks}
            </Typography>
            <IconButton disabled={isLoading || (selectedRange[1] >= totalWeeks)} onClick={(e) => navigate('next')}>
              <ArrowForwardIosSharp classes={{ root: 'small' }} />
            </IconButton>
          </div>
          {!!modifySchDuration && <DurationDropdown
            handleChange={handleChange}
            duration={durationInTypes(duration, durationType)}
            durationType={durationType}
          />}
          {scheduleType === 'workout' && 
            <div className={!!modifySchDuration && 'fml-20'}>
              <WoScheduleMetaFields
                doc={processScheduleMeta(localState)}
                updateLocalState={updateLocalState}
                onChange={handleChange}
                enqueueSnackbar={enqueueSnackbar}
              />
            </div>
          }
          {scheduleType === "meal" && 
            <div className='fml-20'>
              <MlScheduleMetaFields
                doc={processMlScheduleMeta(localState)}
                updateLocalState={updateLocalState}
                onChange={handleChange}
                enqueueSnackbar={enqueueSnackbar}
            />
            </div>
          }
        </div>
        <div className='d-flex align-items-center' style={{columnGap: 55}}>
        {scheduleType === 'meal' && (
            <FormControlLabel
              className="fm-0"
              control={<Checkbox checked={autoRepeatCheck} color="primary" onChange={handleAutoRepeat} />}
              label={
                <Typography className="font_15_600" noWrap>
                  Auto Repeat All Weeks
                </Typography>
              }
            />
          )}
          {!!showImportBtn && <ClrdButton variant='text' onClick={toggleScheduleImport} color='primary' style={{marginRight:'-50px'}}>Import from library</ClrdButton>}
          <Tooltip title='Reorder Weeks' aria-label='Reorder Weeks'>
            <IconButton onClick={toggleReorderDialog}>
              <ImportExport className='text-dark-grey' />
            </IconButton>
          </Tooltip>
        </div>
        {isLoading && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
      </div>
    </div>
  )
};

const MlScheduleMetaFields = ({ doc, onChange, enqueueSnackbar }) =>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(doc);
  const [meta, setMeta] = React.useState({ type: '' });
  const classes = styles();
  const [_minCalories, _maxCalories] = useMemo(() => {
    return [
      !!doc && doc.min_ml_calories && Math.round(doc.min_ml_calories),
      !!doc && doc.max_ml_calories && Math.round(doc.max_ml_calories),
    ];
  }, [doc]);

  const [min_calories, max_calories] = useMemo(() => {
    return [
      !!state && state.min_ml_calories && Math.round(state.min_ml_calories),
      !!state && state.max_ml_calories && Math.round(state.max_ml_calories),
    ];
  }, [state]);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(e, type) {
    setState(doc);
    setAnchorEl(e.currentTarget);
  }

  const handleReset = (type) => {
    const out = _.cloneDeep(state);
    out.ml_calories_overridden = !out.ml_calories_overridden;
    setState({ ...processMlScheduleMeta(_.cloneDeep(out)) });
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [`${name}_ml_calories`]: value && Number(value), ml_calories_overridden: true }));
  };

  const saveChanges = () => {
    if (!!state.ml_calories_overridden) {
      if (!state.max_ml_calories || !state.max_ml_calories) {
        setMeta((prev) => ({ ...prev, error: true }));
        return enqueueSnackbar('This value cannot be empty or zero', { variant: 'error' });
      }
      if (state.max_ml_calories < state.max_ml_calories) {
        setMeta((prev) => ({ ...prev, error: true }));
        return enqueueSnackbar('Minimum value cannot be greater than maximum value', { variant: 'error' });
      }
    }
    onChange(null, { ...state });
    handleClose();
  };

  if (!doc) return null;

  return (
    <div className="d-flex align-items-center">
      <ClrdButton
        className={`d-flex bg-white justify-content-between align-items-center fpx-15`}
        onClick={(e) => handleClick(e, 'meal_calories')}
        style={{ border: '1px solid #D3D9DB' }}
        classes={{ root: classes.metaField }}
        disableRipple
      >
        <Typography className="font_16_500">
          <span className="fmr-5 font_16_700">
            {_minCalories === _maxCalories
              ? _minCalories
              : `${(!!_minCalories && _minCalories + ' -') || ''} ${_maxCalories}`}
          </span>
          {'cals / day'}
        </Typography>
        <KeyDown className={`fp-0`} />
      </ClrdButton>
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: `fb-border small`,
          list: 'fp-0',
        }}
      >
        <div className="fp-20">
          <FormControlLabel
            className="fmb-15"
            control={<Checkbox checked={!state.ml_calories_overridden} color="primary" onClick={handleReset} />}
            label={
              <Typography className="font_13_600" noWrap>
                Calculate Automatically
              </Typography>
            }
          />
          <Typography className="fmb-20">Calories Per Day</Typography>
          <div className={`fmb-10 w-100 d-flex`}>
            <div className="pr-10">
              <FormTextField label={'Min'}>
                <TextField
                  className="w-100"
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleValueChange}
                  disabled={!state.ml_calories_overridden}
                  value={min_calories}
                  id={'meal_calories'}
                  name={'min'}
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === '.') {
                      e.preventDefault();
                    }
                  }}
                  error={meta.error}
                  InputProps={{
                    classes: {
                      root: 'medium w-100',
                      input: 'size_14_600 w-100',
                    },
                  }}
                />
              </FormTextField>
            </div>
            <div className="pl-10">
              <FormTextField label={'Max'}>
                <TextField
                  className="w-100"
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleValueChange}
                  disabled={!state.ml_calories_overridden}
                  value={max_calories}
                  id={'meal_calories'}
                  name={'max'}
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === '.') {
                      e.preventDefault();
                    }
                  }}
                  error={meta.error}
                  InputProps={{
                    classes: {
                      root: 'medium w-100',
                      input: 'size_14_600 w-100',
                    },
                  }}
                />
              </FormTextField>
            </div>
          </div>
          <ClrdButton className="w-100 f-xlarge" color="main" onClick={saveChanges}>
            Apply
          </ClrdButton>
        </div>
      </Menu>
    </div>
  );
}

const WoScheduleMetaFields = ({ doc, onChange, enqueueSnackbar }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState(doc);
  const [meta, setMeta] = React.useState({type:''});

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(e, type) {
    setState(doc);
    setAnchorEl(e.currentTarget);
    setMeta({ type });
  }

  function saveChanges() {
    if(meta.type === 'workout_week' && !!state.wo_week_overridden){
      if (!state.max_wo_week || !state.min_wo_week){
        setMeta(prev => ({ ...prev, error:true }));
        return enqueueSnackbar("This value cannot be empty or zero", { variant: "error" });
      };
      if (state.max_wo_week < state.min_wo_week){
        setMeta(prev => ({ ...prev, error:true }));
        return enqueueSnackbar("Minimum value cannot be greater than maximum value", { variant: "error" });
      }
    };
    if(meta.type !== 'workout_week' &&  !!state.wo_duration_overridden){
      if(!state.min_wo_duration || !state.max_wo_duration){
        setMeta(prev => ({ ...prev, error:true }));
        return enqueueSnackbar("This value cannot be empty or zero", { variant: "error" });
      };
      if (state.max_wo_duration < state.min_wo_duration){
        setMeta(prev => ({ ...prev, error:true }));
        return enqueueSnackbar("Minimum value cannot be greater than maximum value", { variant: "error" });
      }
    };
    onChange(null, { ...state });
    handleClose();
  }

  useEffect(() => {
    let unmount = false;
    if(unmount) return;
    setState(doc);
    return () => unmount = true;
  }, [doc]);

  const handleReset = (type) => {
    const out = _.cloneDeep(state);
    out[type] = !out[type];
    setState({ ...processScheduleMeta(_.cloneDeep(out)) });
  };

  const handleValueChange = (e) => {
    const { name, value, id } = e.target;
    if(id.includes('workout_week')){
      setState(prev => ({ ...prev,
        [`${name}_wo_week`]: value && Number(value), wo_week_overridden: true,
      }));
    } else {
      setState(prev => ({ ...prev,
        [`${name}_wo_duration`]: value && (Number(value) * 60), wo_duration_overridden: true,
      }));
    }
  };

  const [ _minWeek, _maxWeek, _minDuration, _maxDuration ] = useMemo(() => [
      !!doc && doc.min_wo_week,
      !!doc && doc.max_wo_week,
      !!doc && doc.min_wo_duration && Math.ceil(doc.min_wo_duration / 60),
      !!doc && doc.max_wo_duration && Math.ceil(doc.max_wo_duration / 60),
    ], [doc]);
  
  const [min_week, max_week, min_duration, max_duration] = useMemo(() => [
    state.min_wo_week,
    state.max_wo_week,
    state.min_wo_duration && Math.ceil(state.min_wo_duration / 60),
    state.max_wo_duration && Math.ceil(state.max_wo_duration / 60),
  ], [state]);

  const classes = styles();
  if(!doc) return null;
  return (
    <div className='d-flex align-items-center'>
      <ClrdButton
        className={`d-flex bg-white justify-content-between align-items-center fpx-15`}
        onClick={(e) => handleClick(e, "workout_week")}
        style={{ border: '1px solid #D3D9DB' }}
        classes={{ root: classes.metaField }}
        disableRipple
      >
        <Typography className="font_16_500">
          <span className="fmr-5 font_16_700">
            {_minWeek === _maxWeek ? _minWeek : `${(!!_minWeek && (_minWeek + " -")) || ''} ${_maxWeek}`}
          </span>
          {'workouts / week'}
        </Typography>
        <KeyDown className={`fp-0`} />
      </ClrdButton>
      {<ClrdButton
        className={`d-flex bg-white justify-content-between align-items-center fpx-15 fml-20`}
        onClick={(e) => handleClick(e, "workout_duration")}
        style={{ border: '1px solid #D3D9DB' }}
        classes={{ root: classes.metaField }}
        disableRipple
      >
        <Typography className="font_16_500">
          <span className="fmr-5 font_16_700">
            {_minDuration === _maxDuration ? _minDuration : `${(!!_minDuration && (_minDuration + " -")) || ''} ${_maxDuration}`}
          </span>
          {'min / workout'}
        </Typography>
        <KeyDown className={`fp-0`} />
      </ClrdButton>}
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: `fb-border small`,
          list: "fp-0"
        }}
      >
        <div className="fp-20">
          <FormControlLabel className='fmb-15'
            control={
              <Checkbox 
                checked={meta.type === 'workout_week' ? !state.wo_week_overridden : !state.wo_duration_overridden} color="primary" 
                onClick={() => handleReset(meta.type === 'workout_week' ? "wo_week_overridden" : "wo_duration_overridden")}/>}
            label={
              <Typography className="font_13_600" noWrap>
                Calculate Automatically
              </Typography>}
            />
          <Typography className="fmb-20">
            {meta.type === 'workout_week' ? "Workouts per week" : "Duration per workout" }
          </Typography>
          <div className={`fmb-10 w-100 d-flex`}>
            <div className='pr-10'>
              <FormTextField label={"Min"}>
                <TextField
                  className='w-100'
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleValueChange}
                  disabled={meta.type === 'workout_week' ? !state.wo_week_overridden : !state.wo_duration_overridden}
                  value={meta.type === 'workout_week' ? min_week : min_duration}
                  id={meta.type}
                  name={"min"}
                  type="number"
                  onKeyDown={e => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  error={meta.error}
                  InputProps={{
                    classes: {
                      root: "medium w-100",
                      input: "size_14_600 w-100"
                    }
                  }}
                />
              </FormTextField>
            </div>
            <div className='pl-10'>
              <FormTextField label={"Max"}>
                <TextField
                  className='w-100'
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleValueChange}
                  disabled={meta.type === 'workout_week' ? !state.wo_week_overridden : !state.wo_duration_overridden}
                  value={meta.type === 'workout_week' ? max_week : max_duration}
                  id={meta.type}
                  name={"max"}
                  type="number"
                  onKeyDown={e => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  error={meta.error}
                  InputProps={{
                    classes: {
                      root: "medium w-100",
                      input: "size_14_600 w-100"
                    }
                  }}
                />
              </FormTextField>
            </div>
          </div>
          <ClrdButton
            className="w-100 f-xlarge"
            color="main"
            onClick={saveChanges}
          >
            Apply
          </ClrdButton>
        </div>
      </Menu>
    </div>
  );
};

export const ScheduleWeekCard = ({ 
  data, doc, weekIndex, isCopied, onCopy, isPasteMode, weekTitle,
  onPaste, closeCopyMode, onDelete, scheduleDuration, onTitleEdit,
  isWeekTitleEditable,showWeekIndexInTitle,modifySchDuration=true, ...rest  
}) => {
  const customTitle = useMemo(()=>{
    const wkTitle = getWeekTitle(showWeekIndexInTitle,weekIndex, weekTitle );
    return wkTitle;
  },[weekTitle, showWeekIndexInTitle, weekIndex])
  return (
    <div className='fmb-20 position-relative'>
      <CopyOverlay type={"week"} isCopied={isCopied} weekIndex={weekIndex} onClose={closeCopyMode} />
      <div className='bg-white border rounded'>
        <div className='d-flex flex-row align-items-center px-20 py-10'>
         <Typography variant='h3'>{customTitle.primary}{customTitle.secondary && <span className="font_13_500 text-dark-grey ml-10" >{customTitle.secondary}</span> }</Typography>
          {!!isWeekTitleEditable && <IconButton size="small" className="ml-10" onClick={(e)=>onTitleEdit(e,{weekIndex})}><DetailEditIcon/></IconButton>}
          <div className='flex-grow-1' style={{height: 40}}/>
          {isPasteMode && <Tooltip title='Paste Week'><ClrdButton color='invert' onClick={onPaste}
          className="f-sm-med border-0 shadow-none">Paste</ClrdButton></Tooltip>}
          {!isPasteMode && !isCopied && !(weekIndex === 1 && Math.ceil(scheduleDuration/7) === 1) && <div className='d-flex align-items-center'>
            <Tooltip title='Copy Week'>
              <IconButton onClick={onCopy}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
            {!!modifySchDuration && <Tooltip title='Clear Week'>
              <IconButton edge='end' onClick={(e) => onDelete(e, {type: 'week', weekIndex })}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
          </div>}
        </div>
        <div>{rest.children}</div>
      </div>
    </div>
)
};

const getDefaultTitleState = (data) =>{
  if(_.isEmpty(data)) return {
    value :""
  }
  else return data;
}

export const EditTitleDialog = (props) =>{
  const { weekIndex, data, open, onClose, onSave, showWeekIndexInTitle } = props; //data is in format like {w1:{value:"",...and localizationKeys}}
  const [state, setState] = useState(getDefaultTitleState(data));
  const [checked,setCheck] = useState(showWeekIndexInTitle === undefined ? true : showWeekIndexInTitle);
  const [dirty, setDirty] = useState(false);
  const value = _.get(state, 'value');
  const _handleSave = () => {
    const data = {...state};
    data.value = (data.value || "").trim();
    onSave && onSave(weekIndex, data, checked , onClose);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setState((s) => ({ ...s, value }));
    setDirty(true);
  };
  const handleCheck = (e)=>{
    const value = e.target.checked;
    setCheck(value);
    setDirty(true);
  }
  return (
    <Dialog
      title={`Custom Week Title`}
      toolbarClass="height-60"
      buttonColor="primary"
      buttonSize="f-medium"
      open={open}
      onClose={onClose}
      onSave={_handleSave}
      withConfirmation
      isDirty={dirty}
      paperClass="width-500"
      actionText="Save"
    >
      <div className="w-full px-20 pt-20">
        <TextField
          fullWidth
          value={value}
          variant="outlined"
          onChange={handleChange}
          className="mb-15"
          InputProps={{
            classes: {
              root: 'medium w-100',
              input: 'size_14_600 w-100',
            },
          }}
        />
        <FormControlLabel
          className="mb-15"
          label={
            <Typography className="font_14_500 text-dark-grey">
              Show the week sequence number along with the custom title for each week in this schedule.
            </Typography>
          }
          control={<Checkbox color="primary" className="py-10" checked={checked} onChange={handleCheck} />}
        />
      </div>
    </Dialog>
  );
}

export const ScheduleDayContainer = ({
  dayIndex,
  dayData,
  onAddClick,
  dataKey,
  weekIndex,
  fetchedItems={},
  onSwitchChange,
  dragProvided={},
  dragSnapshot={},
  closeCopyMode,
  onCopy,
  onPaste,
  onDeleteItem,
  copyState,
  toggleAlternateDialog,
  toggleDrawer,
  scheduleType,
  openTagDialog,
  onTagDelete,
  ...rest
}) => {
  const {comp} = useContext(FirebaseAuthContext);
  
  const dayTags = comp.data().app_config.tags?.daytags || {};
  const showTags = scheduleType === "workout" && !_.isEmpty(dayTags);
  const idsToRender = (!!dayData && dayData[dataKey]) || [];
  const isChecked = idsToRender[0] !== 'rest';
  const handleSwitch = () => {
    if(isChecked) return onSwitchChange({dayData: { [dataKey]: ['rest'] }, dayIndex, weekIndex, });
    return onSwitchChange({ dayData: { [dataKey]: [] }, dayIndex, weekIndex });
  };
  const handleCopy = onCopy({ source: `w${weekIndex}.d${dayIndex}`, type: "day" });
  const handlePaste = onPaste({ dest: `w${weekIndex}.d${dayIndex}`, type: "day" });
  const isCopied = !!copyState && ((copyState.type === 'day' || copyState.type === 'individualItem') && copyState.source.includes(`w${weekIndex}.d${dayIndex}`));
  const isPasteMode = !!copyState && ((copyState.type === 'day' || copyState.type === 'individualItem') && copyState.source !== `w${weekIndex}.d${dayIndex}`);
  const canPaste = scheduleType !== "workout" ? isPasteMode && idsToRender.length === 0 : isPasteMode;
  return (
    <div
      className={clsx('position-relative h-100 d-flex flex-column')}
      style={{ minWidth:'180px', minHeight: '200px' }}
      {...rest}
    >
      {/* Top Controls */}
      <div
        className={clsx('d-flex align-items-center justify-content-between fmb-10 fpr-6 flex-1', dayIndex !== 1 && 'fpl-6')}
        style={{ maxHeight: '20px' }}>
        <div className='d-flex align-items-baseline'>
          {scheduleType === 'workout' &&
            <div className='fmr-5'>
              <Switch
                size="small"
                color="primary"
                checked={isChecked}
                onChange={handleSwitch}
              />
            </div>
          }
          <Typography className={clsx('font_15_600',scheduleType === 'meal' && "fml-12")}>
            Day {dayIndex}
          </Typography>
        </div>
        {isChecked &&
          <div className='d-flex align-items-center'>
            {canPaste ? (
              <Tooltip title="Paste Day">
                <IconButton onClick={handlePaste} size='small'>
                  <PasteIcon className='text-dark-grey' classes={{root: 'small'}} />
                </IconButton>
              </Tooltip>
            ) : isCopied ? null : (
              <>
                {scheduleType === 'meal' && idsToRender.length > 0 ? null : 
                <Tooltip title="Add Item">
                  <IconButton onClick={onAddClick} size='small'>
                    <AddIcon className='text-dark-grey' classes={{root: 'small'}} />
                  </IconButton>
                </Tooltip>}
                <Tooltip title="Copy Day">
                  <IconButton size='small' onClick={handleCopy}>
                    <CopyIcon classes={{root: 'small2 text-black'}} />
                  </IconButton>
                </Tooltip>
                {showTags && (
                  <Tooltip title="Add Tags">
                    <IconButton size="small" onClick={openTagDialog}>
                      <TagIcon classes={{ root: 'small2' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        }
      </div>

      <div className={clsx("flex-1", dayIndex < 7 && 'border-right')}>
        <TagChips
          className="px-12 pb-10 pt-5"
          dayData={dayData}
          dayIndex={dayIndex}
          weekIndex={weekIndex}
          onTagDelete={onTagDelete}
        />
        {/* WO / ML Cards */}
        <div className={clsx('fpl-12 fpr-12 position-relative flex-1')}>
          <CopyOverlay
            type={'day'}
            isCopied={isCopied && copyState.type === 'day'}
            onClose={closeCopyMode}
            dayIndex={dayIndex}
            className='fpx-12'
          />
          {idsToRender.length > 0 ? idsToRender.map((itemId, i) => {
            if(itemId === 'rest') return <RestDayCard key={(`w${weekIndex}.d${dayIndex}.rest`)} />;
            return (
              <Draggable
                key={(`w${weekIndex}.d${dayIndex}.${itemId}`)}
                draggableId={`w${weekIndex}.d${dayIndex}.${itemId}.${i}`}
                index={weekIndex + dayIndex}
              >
                  {(provided, snapshot) => {
                    return (
                      <Fade in={true}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {scheduleType === "workout" ?
                            <WorkoutCard
                              minWidth={172}
                              isCopied={!!copyState && (copyState.type === 'individualItem' &&
                                copyState.source === `w${weekIndex}.d${dayIndex}` && copyState.toPaste === itemId && copyState.sourceIndex === i )}
                              onCopy={onCopy({ type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i })}
                              onDelete={(e) => onDeleteItem(e, {type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i})}
                              closeCopyMode={closeCopyMode}
                              woData={_.get(fetchedItems, itemId.includes('/') ? itemId.split('/')[0] : itemId, {})}
                              itemId={itemId}
                              id={`w${weekIndex}.d${dayIndex}.${itemId}.${i}`}
                              className={clsx(i < idsToRender.length - 1 && 'fmb-10')}
                              toggleAlternateDialog={() =>
                                toggleAlternateDialog({ type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i, dayIds: idsToRender.filter(o => o !== itemId),
                                 dayIndex,
                                 weekIndex,
                                 woIndex:i
                              })}
                              toggleDrawer={() => toggleDrawer({
                                ..._.get(fetchedItems, itemId.includes('/') ? itemId.split('/')[0] : itemId, {}),
                                 displayEditOption: true,
                                 dayIndex,
                                 weekIndex,
                                 woIndex:i
                              })}
                            /> :
                            <ScheduleMlCard
                              minWidth={172}
                              isCopied={!!copyState && (copyState.type === 'individualItem' &&
                                copyState.source === `w${weekIndex}.d${dayIndex}` && copyState.toPaste === itemId && copyState.sourceIndex === i )}
                              onCopy={onCopy({ type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i })}
                              onDelete={(e) => onDeleteItem(e, {type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i})}
                              closeCopyMode={closeCopyMode}
                              data={_.get(fetchedItems, itemId.includes('/') ? itemId.split('/')[0] : itemId, {})}
                              itemId={itemId}
                              id={`w${weekIndex}.d${dayIndex}.${itemId}.${i}`}
                              className={clsx(i < idsToRender.length - 1 && 'fmb-10')}
                              toggleAlternateDialog={() => toggleAlternateDialog({ type: "individualItem", source:`w${weekIndex}.d${dayIndex}`, itemId, sourceIndex: i })}
                              toggleDrawer={() => toggleDrawer({
                                ..._.get(fetchedItems, itemId.includes('/') ? itemId.split('/')[0] : itemId, {}), displayEditOption: true,
                                dayIndex,
                                weekIndex,
                                woIndex:i
                              })}
                            />
                          }
                        </div>
                      </Fade>
                    );
                  }}
              </Draggable>
            );
          }): (
            <div className='d-flex align-items-center justify-content-center' style={{height: '101.5px', minWidth: "172px"}}>
              <Typography className='font_13_600'>
                No {scheduleType} Added
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export const RestDayCard = ({ minified }) => {
  const classes = styles({ minified });
  return (
  <Card 
    className={clsx(classes.card, 'd-flex align-items-center justify-content-center')} 
    style={{ backgroundColor: '#F5F9FF', border: 'none'}}
  >
      <Typography className='font_13_500' style={{ maxWidth: '90%' }} noWrap>
        {"Rest"}
      </Typography>
    </Card>
)};

export const CopyOverlay = (props) => {
  const { type, dayIndex, weekIndex, isCopied, onClose, className } = props;
  const classes = styles({ type });

  if(!isCopied) return null;
  return (
    <Fade in>
      <div className={clsx(className, classes.overlayCardRoot )}>
        <div className={clsx(classes.overlayCard)}>
          <div>
            <IconButton
              onClick={onClose}
              classes={{root: 'bg-white'}} 
              size={type !== 'week' && 'small' }
              style={{top: '10px', position: 'absolute', right:'20px' }}
            >
              <Close classes={{root: type !== 'week' && 'x-small' }} />
            </IconButton>
          </div>
          <Typography className='text-white font_15_600'>
            {type === 'day' ? `Day ${dayIndex}` : type === 'week' ? `Week ${weekIndex}` :'' } Copied
          </Typography>
        </div>
      </div>
    </Fade>
  )
}

export function TagsDialog({ onClose, dayData = [], onSubmit }) {
  const classes = styles();
  const { comp } = useContext(FirebaseAuthContext);
  const dayTagsOptions = comp.data().app_config.tags.daytags;
  const dayTagsKeys = Object.keys(dayTagsOptions);
  const [selectedTags, setSelectedTags] = useState(() => {
    const {tags, rest, w_opt} = dayData;
    if(tags && Array.isArray(tags)) return tags;
    if(rest) return ['rest'];
    if(w_opt) return ['w_opt']; 
    return [];
  });
  
  const handleChange = (e, checked) => {
    const { name } = e.target;

    setSelectedTags((prev) => {
      if (checked) {
        return [...prev, name];
      }
      return prev.filter((tag) => tag !== name);
    });
  };

  function handleSave() {
    onSubmit(selectedTags);
  }

  return (
    <Dialog title="Available Tags" open onSave={handleSave} actionText="Save" onClose={onClose} paperClass="width-400">
      <div className="p-20 d-flex flex-wrap" style={{ gap: 15 }}>
        {dayTagsKeys.map((tagKey) => {
          const isChecked = selectedTags.includes(tagKey);

          if(!dayTagsOptions[tagKey] || !dayTagsOptions[tagKey].value) return null;
          
          return (
            <Checkbox
              checked={isChecked}
              onChange={handleChange}
              name={tagKey}
              icon={
                <Chip
                  key={tagKey}
                  label={dayTagsOptions[tagKey].value}
                  className={clsx(classes.chip, classes.chipLarge)}
                  classes={{ label: 'font_13_500' }}
                />
              }
              checkedIcon={
                <Chip
                  key={tagKey}
                  label={dayTagsOptions[tagKey].value}
                  className={clsx(classes.chip, classes.chipLarge, classes.selectedChip)}
                  classes={{ label: 'font_13_500' }}
                />
              }
              className="p-0"
            />
          );
        })}
      </div>
    </Dialog>
  );
}

export function TagChips({ dayData = {}, onTagDelete, weekIndex, dayIndex, className}) {
  const { comp } = useContext(FirebaseAuthContext);
  const classes = styles();
  const { tags , rest, w_opt } = dayData;

  const dayTagsOptions = comp.data().app_config.tags?.daytags || {};
  const showTags = !_.isEmpty(dayTagsOptions) && (!_.isEmpty(tags) || rest || w_opt);

  const displayTags = (function() {
    if(tags && Array.isArray(tags)) return tags;
    if (rest) {
      return ['rest'];
    } 
    if (w_opt) {
      return ['w_opt'];
    }
    return [];
  })()

  function handleDelete(tag) {
    onTagDelete({ tag, weekIndex, dayIndex });
  }

  if (!showTags) return null;
  
  return (
    <div className={clsx("d-flex flex-wrap", className)} style={{ gap: 10 }}>
      {displayTags?.map((tag) => {
        if(!dayTagsOptions[tag] || !dayTagsOptions[tag].value) return null;
        return (
          <Chip
            key={tag}
            label={dayTagsOptions[tag].value}
            className={clsx(classes.chip, classes.chipSmall)}
            classes={{ label: 'font_12_500 px-8 text-black mr-2px', deleteIcon: 'text-black' }}
            onDelete={onTagDelete && (() => handleDelete(tag))}
            deleteIcon={<CloseRounded color='primary' style={{ width: 12 }} />}
          />
        );
      })}
    </div>
  );
}
