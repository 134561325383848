import _ from "lodash";
import moment from "moment-timezone";
import { DEFAULT_BUFFER_DAYS, oprtnlDateFormat, } from "fitbud/utils/constants";
import { durationInTypes } from "fitbud/views/schedulePlanner/helper";
import { pluralize } from "fitbud/utils/helpers";

export const getConvertedDuration = (duration, durationType) => {
    const converted_duration = !!duration ? Math.ceil(durationInTypes(duration, durationType)) : void 0;
    let durationStr = !!converted_duration ? `${converted_duration} ${durationType && _.capitalize(durationType.slice(0, -1))}${pluralize(converted_duration)}` : ''
    return durationStr
}

const absCeil = (v) => Math.ceil(Math.abs(v))
export const getUserTimeZone = (userData) => _.get(userData, "profile.time_zone", moment.tz.guess())
export const getUserLocalDate = userData =>  moment.tz(getUserTimeZone(userData))
export const getUserAssignTillDate = userData => moment.tz(_.get(userData, "assigned_till"), oprtnlDateFormat, getUserTimeZone(userData)).endOf('day')

export const getUserPlanName = (userData, planDoc) => _.get(planDoc, "purchase.pack.ref_name")  || _.get(userData, "currPurchase.pack.ref_name") || _.get(userData, "plan_assign.pack.ref_name") || "Custom"
export const getUserPlanStartDate = userData =>  moment.tz(userData.startDate, oprtnlDateFormat, getUserTimeZone(userData)).startOf('day')
export const getUserPlanEndDate = userData =>  getUserPlanStartDate(userData).add(userData.duration - 1, 'days');
export const getUserPlanRenewalDate = userData => moment(_.get(userData, "subscription.renewsOn"))
export const getUserPlanCancellationDate = userData => moment(_.get(userData, "subscription.cancelledOn"))
export const getUserPlanDurationWithType =  userData => {
    const { duration, durationType } = userData
    return getConvertedDuration(duration, durationType)
}
export const getUserCurrentWeekAndDay = (userData) => {
    const dateDiff = moment(getUserLocalDate(userData)).diff(getUserPlanStartDate(userData), "day");
    let week = parseInt(dateDiff / 7) + 1;
    let day = parseInt(dateDiff % 7) + 1;
    return { week, day }
}

export const getSubsDurationType = userData => {
    const days = _.get(userData, 'currPurchase.pack.duration')
    const subsD = _.get(userData, 'currPurchase.pack.subs_duration_type');
    if (!!subsD && subsD !== 'random') return `${_.capitalize(subsD)}ly`;
    if(!days) return ''
    else if (days >= 336) return 'Yearly';
    else if (days >= 84) return 'Quarterly';
    else if (days >= 28) return 'Monthly';
    else return "Weekly"
}
export const isSubscriptionUser = (userData) => _.get(userData, "currPurchase.pack.mode") === "subscription"
export const isSubCancelled = (userData) => _.get(userData, 'subscription.status') === 'cancelled'
export const hasNotStartedOnboarding = (userData) => !userData.onboarding_start_date || userData.onboarding_start_date === '20200101'
export const hasCompletedOnboarding = (userData) => userData.onboarding_complete
export const planNotAssigned = (userData) => !userData.current_plan_status || userData.current_plan_status === "inactive"
export const isSelfSignupUser = (userData) => !_.get(userData, "currPurchase") && !_.get(userData, "plan_assign") && planNotAssigned(userData)
export const isNewUser = (userData) => (!hasCompletedOnboarding(userData) || planNotAssigned(userData)) && !userData.deactivated

export const isActiveUser = (userData) => userData.current_plan_status === "activated"
export const isCompletingSoon = (userData, days = DEFAULT_BUFFER_DAYS) => isActiveUser(userData) && !isSubscriptionUser(userData) && getUserPlanEndDate(userData).isSameOrBefore(getUserLocalDate(userData).add(days, 'days'))
export const isRenewalUpcoming = (userData, days = DEFAULT_BUFFER_DAYS) => isActiveUser(userData) && isSubscriptionUser(userData) && getUserPlanRenewalDate(userData).isSameOrBefore(getUserLocalDate(userData).add(days, 'days')) && getUserPlanRenewalDate(userData).isSameOrAfter(getUserLocalDate(userData))
export const isRenewalOverdue = (userData) => {
  if (!isActiveUser(userData) || !isSubscriptionUser(userData)) return false;
  if (isTrialing(userData)) {
    const r = getTrialRemainingDays(userData);
    if (r < 0) return Math.abs(r);
  }
  if (getUserPlanRenewalDate(userData).isBefore(getUserLocalDate(userData)))
    return getPlanRenewsDays(userData);
  return false;
};
export const isUpdateOverdue = (userData) => isActiveUser(userData) && getUserAssignTillDate(userData).isBefore(getUserLocalDate(userData))
export const isUpdateRequired = (userData, days = DEFAULT_BUFFER_DAYS) => isActiveUser(userData) && userData.assigned_till !== userData.endDate && getUserAssignTillDate(userData).isSameOrBefore(getUserLocalDate(userData).add(2, 'days'))


export const isDeactivatedUser = (userData) => userData.deactivated
export const planExpired = (userData) => userData.current_plan_status === "expired"
export const hasPlanCompleted = (userData, days= DEFAULT_BUFFER_DAYS) => userData.current_plan_status === "expired" && getUserPlanEndDate(userData).add(days, 'days').isBefore(getUserLocalDate(userData))
export const isRecentlyCompleted = (userData, days = DEFAULT_BUFFER_DAYS) => userData.current_plan_status === "expired" && getUserPlanEndDate(userData).add(days, 'days').isAfter(getUserLocalDate(userData))
export const isInactiveUser = (userData) => planExpired(userData) || isDeactivatedUser(userData)
export const isSignUpPending = (userData) => !userData.onboarding_start_date
export const isPlansToAssignUser = (userData) => planNotAssigned(userData) && (_.get(userData, "currPurchase") || _.get(userData, "plan_assign"))


export const getPlanOverdueDays = (userData) => absCeil(getUserLocalDate(userData).diff(getUserAssignTillDate(userData), 'days', true))
export const getPlanEndsDays = (userData) => getUserPlanEndDate(userData).diff(getUserLocalDate(userData), 'days', true)
export const getPlanStartsDays = (userData) => absCeil(getUserPlanStartDate(userData).diff(getUserLocalDate(userData), 'days', true))
export const getPlanRenewsDays = (userData) => absCeil(getUserPlanRenewalDate(userData).diff(getUserLocalDate(userData), 'days'))
export const getUserOnboardedDays = (userData) => absCeil((userData.questionnaire_timestamp && moment(userData.questionnaire_timestamp.toMillis()).diff(getUserLocalDate(userData), 'days')) || 0)
export const getPlanAssignedDays = (userData) => absCeil(getUserAssignTillDate(userData).diff(getUserLocalDate(userData), 'days', true))
export const getUserStartedDays = (userData) => getUserLocalDate(userData).diff(moment.tz(userData.onboarding_start_date, oprtnlDateFormat, getUserTimeZone(userData)).startOf('day'), 'days');

export const getState = (userData) => {
    // if(isSignUpPending(userData)) return 'pendingSignIn';
    if(isInactiveUser(userData)) return 'inactive';
    if(isActiveUser(userData)) return 'active';
    if(isPlansToAssignUser(userData)) return 'plansToAssign';
    if(isSelfSignupUser(userData)) return 'leads';
}
export const getStateDisplayText = (state) => {
    switch(state){
        case 'inactive':
            return 'Inactive';
        case 'active':
            return 'Active';
        case 'plansToAssign':
            return 'Plans to assign';
        case 'leads':
            return 'Lead';
        default:
            return '';
    }
}
export const getUserPaymentMode = (userData) => {
    if(_.get(userData, 'currPurchase.pack.method_ios') === 'iap') return 'Apple';
    if(_.get(userData, 'currPurchase')) return 'Third Party';
    if(_.get(userData, 'plan_assign')) return 'Manually Added';
    return null;
}

export const isTrialing = (userData) => _.get(userData, 'trial.status') === "trialing";
export const getTrialRemainingDays = (userData) => moment(_.get(userData, 'trial.billing_date')).diff(moment(), 'day')

export const getUserStatus = (userData, days = DEFAULT_BUFFER_DAYS) => {
    if(isSelfSignupUser(userData)) return getLeadsUserStatus(userData);
    // if(isSignUpPending(userData)) return  getSignUpPendingUserStatus(userData);
    if(isPlansToAssignUser(userData)) return getPlansToAssignUserStatus(userData);
    if(isActiveUser(userData)) return getActiveUserStatus(userData, days)
    if(isInactiveUser(userData)) return getInactiveUserStatus(userData, days)
}

export const getUserHeaderDetails = (userData, planData) => {
    if(isSelfSignupUser(userData)) return getLeadsUserHeaderDetails(userData, planData);
    // if(isSignUpPending(userData)) return  getPendingSignUpUserHeaderDetails(userData, planData);
    if(isPlansToAssignUser(userData)) return getPlansToAssignUserHeaderDetails(userData, planData);
    if(isActiveUser(userData)) return getActiveUserHeaderDetails(userData, planData)
    if(isInactiveUser(userData)) return getInactiveUserHeaderDetails(userData, planData)
}

function getLeadsUserStatus(userData) {
    const m = getUserStartedDays(userData)
    const n = getUserOnboardedDays(userData)
    switch (true) {
        case !hasCompletedOnboarding(userData):
            return { state: "Onboarding Pending", text: `${hasNotStartedOnboarding(userData) ? '' : `Signed in  ${m ? `${m} day${pluralize(m)} ago`: "today"}`}`, color: "neutral" };
        case planNotAssigned(userData):
            return { state: "Onboarding Done", text: `Onboarded ${n ? `${n} day${pluralize(n)} ago`: "today"}`, color: "yellow" };
        default:
            return null;
    }
}

function getPlansToAssignUserStatus(userData) {
    const p = isRenewalOverdue(userData);
    if (p) {
        return { state: "Payment Pending", text: `Overdue by ${p} day${pluralize(p)}`, color: "red" };
    }
    const m = getUserStartedDays(userData)
    const n = getUserOnboardedDays(userData)
    const r = getTrialRemainingDays(userData);
    switch (true) {
        case isSignUpPending(userData):
            return { state: "Signup Pending", text: "", color: "neutral" };
        case isTrialing(userData):
            return { state: "In Trial", text: `${r ? `${r} day${pluralize(r)} left until trial ends` : 'Ends today'}` , color: "yellow" };
        case !hasCompletedOnboarding(userData):
            return { state: "Onboarding Pending", text: `${hasNotStartedOnboarding(userData) ? '' : `Signed in  ${m ? `${m} day${pluralize(m)} ago` : "today"}`}`, color: "neutral" };
        default:
            return { state: "Onboarding Done", text: `Onboarded ${n ? `${n} day${pluralize(n)} ago` : "today"}`, color: "yellow" };
    }
}

function getActiveUserStatus(userData, days) {
    const p = isRenewalOverdue(userData);
    if (p) {
        return { state: "Payment Pending", text: `Overdue by ${p} day${pluralize(p)}`, color: "red" };
    }
    const m = getPlanOverdueDays(userData);
    const n = getPlanRenewsDays(userData);
    const o = getPlanAssignedDays(userData);
    const q = absCeil(getPlanEndsDays(userData));
    const r = getTrialRemainingDays(userData);
    switch (true) {
        case isTrialing(userData):
            return { state: "In Trial", text: `${r ? `${r} day${pluralize(r)} left until trial ends` : 'Ends today'}` , color: "yellow" };
        case isRenewalUpcoming(userData, days):
            return { state: "Upcoming Renewal", text: `Renews ${n ? `in ${n} day${pluralize(n)}`: "today"}`, color: "green" };
        case isUpdateOverdue(userData):
            return { state: "Update Schedule", text: `Overdue by ${m} day${pluralize(m)}`, color: "red" };
        case isUpdateRequired(userData):
            return { state: "Update Schedule", text: `Assigned for ${o} day${pluralize(o)}`, color: "yellow" };
        case isCompletingSoon(userData, days):
            return { state: "Completing Soon", text: `Completes ${q ? `in ${q} day${pluralize(q)}`: "today"}`, color: "yellow" };
        default:
            return { state: "Active", text: "", color: "green" };;
    }
}

function getInactiveUserStatus(userData, days) {
    const n = Math.abs(Math.floor(getPlanEndsDays(userData)));
    const deactivatedOn = moment(userData.deactivatedOn).isValid() && moment(userData.deactivatedOn).format('DD MMM')
    switch (true) {
        case isDeactivatedUser(userData):
            return { state: "Deactivated", text: `${ deactivatedOn ? `Deactivated on ${deactivatedOn}`: ''}`, color: "red" };
        case hasPlanCompleted(userData, days):
            return { state: "Plan Completed", text: "", color: "neutral" };
        case isRecentlyCompleted(userData, days):
            return { state: "Recently Completed", text: `Completed ${n} day${pluralize(n)} ago`, color: "yellow" };
        default:
            return null;
    }
}

function getLeadsUserHeaderDetails() {
    return {
        L1: 'No Active Plan',
        L2: 'Start Date : N/A'
    }
}

function getPlansToAssignUserHeaderDetails(userData, planData) {
    const isSubsUser = isSubscriptionUser(userData)
    const startDate = getUserPlanStartDate(userData)
    return {
        L1: _.truncate(getUserPlanName(userData, planData), {'length': 20}),
        L2: `${isSubsUser? getSubsDurationType(userData) : getUserPlanDurationWithType(userData)} ${` • Start - ${startDate.format("DD MMM YYYY")}` }`
    }
}

function getActiveUserHeaderDetails(userData, planData) {
    const { week, day } = getUserCurrentWeekAndDay(userData)
    const isSubsUser = isSubscriptionUser(userData)
    const subText = isSubCancelled(userData) ? ` • Cancelled on ${getUserPlanCancellationDate(userData).format("DD MMM YYYY")}` : ` • Renews on ${getUserPlanRenewalDate(userData).format("DD MMM YYYY")}`
    return {
        L1:  `${_.truncate(getUserPlanName(userData, planData), {'length': 20})}${ day > 0 ? ` • W${week} D${day}`: ''}`,
        L2: `${isSubsUser ? getSubsDurationType(userData) : getUserPlanDurationWithType(userData)} ${isSubsUser ? subText  : ` • ${getUserPlanStartDate(userData).format('DD MMM')} - ${getUserPlanEndDate(userData).format('DD MMM YYYY')}`}`
    }
    
}

function getInactiveUserHeaderDetails(userData, planData) {
    const planDur = getUserPlanDurationWithType(userData)
    const planStartDate = getUserPlanStartDate(userData)
    const planEndDate = getUserPlanEndDate(userData)
    return {
        L1:  _.truncate(getUserPlanName(userData, planData), {'length': 20}),
        L2: `${planDur ? `${planDur} • ` : ''}${planStartDate.isValid() ? (planStartDate.format('DD MMM') + ' - '): 'Start Date : N/A'}${planStartDate.isValid() && planEndDate.isValid() ? (planEndDate.format('DD MMM YYYY')): ''}`
    } 
}

export const findPlanPeriod = (date, planStartDate, planDuration, viewLimit='weekly') => {
    let out = {};
    const planStartMoment = moment(planStartDate, oprtnlDateFormat);
    let diffFromStart = moment(date).diff(moment(planStartDate, oprtnlDateFormat), 'days');
    if (diffFromStart > planDuration) diffFromStart = planDuration;
    if(viewLimit === 'weekly'){
      let i = Math.floor(diffFromStart / 7);
      const startDate = planStartMoment.clone().add((i * 7), 'days');
      let endDate = startDate.clone().add(6, 'days');
      if(endDate.clone().isAfter(planStartMoment.clone().add(planDuration - 1, 'days'))) 
        endDate = planStartMoment.clone().add(planDuration - 1, 'days');
      if(moment().isAfter(startDate) && moment().isBefore(endDate))
        endDate = moment();
      let week = {
      name: `Week ${i+1}`,
      startDate: startDate.clone().toDate(),
      endDate: endDate.clone().endOf('day').toDate(),
      };
      out = week;
    } else {
      let i = Math.floor(diffFromStart / 30);
      let startDate = planStartMoment.clone().add((i * 30), 'days').startOf('month');
      if(startDate.isBefore(planStartMoment.clone())) startDate = planStartMoment.clone();
      let endDate = startDate.clone().endOf('month');
      if(endDate.clone().isAfter(planStartMoment.clone().add(planDuration - 1, 'days')))
        endDate = planStartMoment.clone().add(planDuration - 1, 'days');
      if(moment().isAfter(startDate) && moment().isBefore(endDate))
        endDate = moment();
      let week = {
        name: `Month ${i+1}`,
        startDate: startDate.clone().toDate(),
        endDate: endDate.clone().endOf('day').toDate(),
      }
      out = week;
    }
    return out;
  }
export const getUserLastSeen = (userDoc) => {
    const { app_info_ios, app_info_android, uid } = userDoc;
    let out = null;
    if(app_info_ios && !!app_info_ios.last_seen){
      out = app_info_ios.last_seen.toDate();
    }
    if(app_info_android && !!app_info_android.last_seen){
      if(!out) out = app_info_android.last_seen.toDate();
      if(!!out && moment(out).isBefore(app_info_android.last_seen.toDate())){
        out = app_info_android.last_seen.toDate();
      };
    }
    if(!out){
      const { last_seen } = userDoc[uid] || {};
      if(!!last_seen) out = last_seen.toDate();
    }
    return out || '';
};
const DEFAULT_ACTIVITY_METRICS=['steps','distance','flights','energy'];
export const showActivityMetric=(app_config_activities=[])=>{
    if(!app_config_activities || (!!app_config_activities && !app_config_activities.length)){
        app_config_activities=[...DEFAULT_ACTIVITY_METRICS];
    }
    return[
        app_config_activities.includes('steps'),
        app_config_activities.includes('distance'),
        app_config_activities.includes('flights'),
        app_config_activities.includes('energy'),
      ]
}


export const getDefaultSessionAssigned = ()=>{
    return {
    //   
    pack: {id: 'sessions_invite', pack_id:'sessions_invite'},
    productId:'sessions_invite',
    src: 'invite',
    app: false,
    status :'cancelled', //default is cancelled
    dateFirst: new Date().toISOString(),
    dateLast: new Date().toISOString(),
    dateExpiry : moment().add(1, "year").toDate().toISOString(),
    dateCancel : null,
    dateConversion : null,
    accessType : 'sessions',
    accessValue : 0,
    accessCalls : null,
    trialStatus : null,
    sessionAssigned :[],
    };
}


export const getPlanAssignPurchase = (pack, vid_call, startDate) =>{
    if(!pack) return {};
    const { id, duration, duration_type, ref_name, title } = pack;
    const mode = "one_time";
    const pack_id = pack?.pack_id || "custom";
    return {
      pack: { id: id || null, pack_id: pack_id, mode, duration_type, ref_name, title },
      productId: pack_id,
      src: 'invite',
      app: false,
      status: 'active',
      dateFirst: moment(startDate).toDate().toISOString(),
      dateLast: moment(startDate).toDate().toISOString(),
      dateExpiry: moment(startDate).add(duration - 1, 'days').toDate().toISOString(),
      dateCancel: null,
      dateConversion: null,
      accessType: 'membership',
      accessValue: duration,
      accessCalls: vid_call || null,
      trialStatus: null,
      sessionAssigned: [],
      durationType :duration_type ,
      mode,
    };
}